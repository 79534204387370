var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "checkbox-item", on: { click: _vm.onClick } },
    [
      !_vm.checked
        ? _c("CheckboxBlankOutlineIcon", {
            staticClass: "icon",
            attrs: { fillColor: "#669cd9" },
          })
        : _c("CheckboxMarkedIcon", {
            staticClass: "icon",
            attrs: { fillColor: "#669cd9" },
          }),
      _vm._t("default", function () {
        return [_vm._v(_vm._s(_vm.text))]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }